import React from 'react';
import { Link } from 'react-router-dom';
import { getFullUrl } from '../../../lib/api_helper';

export default function ProjectIcon({ id, name, url, photo }) {
  return (
    <li>
      <Link to={ `/projects/${id}` }>
        <img src={ getFullUrl(photo.thumb.url) } />
        <span>{ name }</span>
      </Link>
    </li>
  );
}
