import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function({ center }) {
  let spinner = <Spinner animation="border" />
  if( (typeof(center) === 'undefined') || center )
    spinner = <center>{ spinner }</center>

  return spinner
}
