import React from 'react';
import PageWithAsyncText from '../page_with_async_text';
import TextBlock from '../../text_block';
import imgBio from '../../../../assets/images/biography.jpg';

function BioBody({ text }) {
  return (
    <div>
      <TextBlock text={ text } />
      <p style={{ textAlign: 'center' }}>
        <img src={ imgBio } className="responsive" alt="Biography Image" />
      </p>
    </div>
  );
}

export default function Bio() {
  return <PageWithAsyncText code="biography" Component={ BioBody } />
};
