import Home from './home/home';
import Bio from './bio/bio';
import Contact from './contact/contact';
import Folders from './folders/folders';
import Projects from './projects/projects';
import Statement from './statement/statement';

export {
  Home,
  Bio,
  Contact,
  Folders,
  Projects,
  Statement
};
