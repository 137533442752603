import axios from 'axios';

const HEADERS = {
  'Access-Control-Allow-Origin': true,
  'X-Requested-With': 'XMLHttpRequest'
};


// NOTE: this is also used to generate urls for photos, not only for the API
// so do not refactor to include the `api/` part
export function getFullUrl(relativeUrl) {
  return `${process.env.BASE_DOMAIN}${relativeUrl}`;
}

export function getJson(relativeUrl) {
  return axios.get(
    getFullUrl(relativeUrl),
    HEADERS
  );
}

export function postJson(relativeUrl, params) {
  return axios.post(
    getFullUrl(relativeUrl),
    params,
    HEADERS
  );
}

export function getErrorMessage(err, defaultMsg = 'An unexpected error happened. Please try again') {
  if( err.response && err.response.data )
    return err.response.data.message;
  if( err.message )
    return `Unable to send the request (${err.message})`;
  return defaultMsg;
}
