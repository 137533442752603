import React from 'react';
import imgHome from '../../../../assets/images/iteration.jpg';
import classnames from 'classnames';
import styles from './home.css';

function BioBody() {
  return (
    <div className={ styles.home }>
      <p style={{ textAlign: 'center' }}>
        <img className={ classnames(styles.image, 'responsive') } src={ imgHome } alt="Iteration" />
      </p>
      <h3>Iteration</h3>
    </div>
  );
}

export default function Home() {
  return <BioBody />
};
