import React from 'react';
import { Link } from 'react-router-dom';


function ObjectLink({ to, children }) {
  return (
    <Link to={ to }>
      { children }
    </Link>
  )
}

export function FolderLink({ folder, children }) {
  return <ObjectLink to={ `/folders/${folder.id}` } children={ children } />
}

export function ProjectLink({ project, children }) {
  return <ObjectLink to={ `/projects/${project.id}` } children={ children } />
}
