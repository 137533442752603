import React, { useState, useEffect } from 'react';
import Spinner from '../spinner';
import ErrorPanel from '../error_panel'
import { getJson } from '../../lib/api_helper'

export default function PageWithAsyncText({ code, Component }) {
  const [text, setText] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    getJson(`api/v1/texts/${code}`)
    .then(resp => setText(resp.data.text.value))
    .catch(err => setError(true));
  }, []);

  if( error )
    return (<ErrorPanel />);
  if( text )
    return (<Component text={ text } />);
  return (<Spinner />);
};
