import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../spinner';
import ErrorPanel from '../../error_panel'
import { getJson, getFullUrl } from '../../../lib/api_helper'
import { imageStyles } from '../../../lib/util'
import styles from './projects.css';


function withSizeUnit(value) {
  if( !value )
    return null;
  return `${value}"`;
}

function formatDimensions({ width, height, depth }) {
  return [width, height, depth].map( x => withSizeUnit(x) ).filter( x => x ).join(' x ');
}

function DetailsRow({ label, text }) {
  if( !text )
    return null
  return (
    <tr>
      <td><label>{ label }: </label></td>
      <td>{ text }</td>
    </tr>
  )
}

function ProjectDetails({ dimension, medium, year }) {
  return (
    <table className={ styles.details }>
      <tbody>
        <DetailsRow label="Dimension" text={ formatDimensions(dimension) } />
        <DetailsRow label="Medium" text={ medium } />
        <DetailsRow label="Year" text={ year } />
      </tbody>
    </table>
  );
}

export default function Projects() {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    getJson(`api/v1/projects/${id}`)
    .then(resp => setProject(resp.data.project))
    .catch(err => setError(true));
  }, []);

  if( error )
    return (<ErrorPanel />);
  if( !project )
    return (<Spinner />);

  return (
    <div className={ styles.layout }>
      <div className={ styles.grid }>
        <div className={ styles.cellDetails }>
          <div>
            <h2>{ project.name }</h2>
            <ProjectDetails {...project} />
          </div>
        </div>
        <div className={ styles.cellPhoto }>
          <img src={ getFullUrl(project.photo.large.url) } className="responsive" />
          <div className={ styles.photo } style={ imageStyles(project.photo) }></div>
        </div>
      </div>
    </div>
  );
}
