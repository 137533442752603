import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route, location } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Container from 'react-bootstrap/Container';
import Header from './components/header';
import { Home, Bio, Contact, Folders, Projects, Statement } from './components/pages/all_pages';

// If using SASS, review: https://react-bootstrap.github.io/getting-started/introduction/#sass
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap-theme.min.css';
// import 'bootstrap/dist/css/bootstrap-utilities.min.css';
import styles from './app.css';


const history = createBrowserHistory();

const App = () => {
  return (
    <React.StrictMode>
      <Router history={ history }>
        <Header />
        <Container className={ styles.container }>
          <Switch>
            <Route path="/statement" component={ Statement } />
            <Route path="/projects/:id" component={ Projects } />
            <Route path="/folders/:id" component={ Folders } />
            <Route path="/folders" component={ Folders } />
            <Route path="/contact" component={ Contact } />
            <Route path="/bio" component={ Bio } />
            <Route path="/" component={ Home } />
          </Switch>
        </Container>
      </Router>
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementsByTagName('react-component')[0]);
