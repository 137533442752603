import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { postJson, getErrorMessage } from '../../../lib/api_helper'
import imgConfirmation from '../../../../assets/images/confirmation.png'
import styles from './contact.css';


const FORM_DATA = [
  {
    name: 'name',
    caption: 'Name',
    placeholder: 'Enter your first and last name',
    type: 'text',
    validation: {
      required: true
    }
  },
  {
    name: 'email',
    caption: 'Email',
    placeholder: 'Enter your email address',
    type: 'email',
    validation: {
      required: true,
      pattern: /^[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    }
  },
  {
    name: 'phone',
    caption: 'Phone',
    placeholder: 'Enter your phone number (digits only)',
    type: 'tel',
    validation: {
      required: true,
      pattern: /^\+?\d{10,}$/
    }
  },
  {
    name: 'subject',
    caption: 'Subject',
    placeholder: 'Enter a subject',
    type: 'text',
    validation: {
      required: true
    }
  },
  {
    name: 'message',
    caption: 'Message',
    placeholder: 'Enter your message to the artist',
    as: 'textarea',
    rows: 6,
    validation: {
      required: true
    }
  }
];

const ERROR_CLASSES = {
  ctrlClass: 'is-invalid',
  lblClass: 'text-danger'
}

function ContactForm({ onSubmit, state, register, errors, onDismissError }) {
  return (
    <Form onSubmit={ onSubmit }>
      { (state.code == 'error') && <AlertBox message={ state.message } onDismiss={ onDismissError } /> }
      {
        FORM_DATA.map( (props, i) => <Field
          key={ i }
          {...props}
          register={ register }
          errors={ errors }
        /> )
      }
      <div className="controls">
        <SubmitButton pending={ state.code == 'pending' } />
      </div>
    </Form>
  );
}

function Field({register, errors, caption, name, validation, ...props}) {
  const { ctrlClass, lblClass } = errors[name] ? ERROR_CLASSES : {};
  return (
    <Form.Group as={Row} className="mb-3" controlId={ `contact_form_${name}` }>
      <Form.Label column sm={2} className={ lblClass }>
        { caption }
      </Form.Label>
      <Col sm={10}>
        <Form.Control {...props} {...register(name, validation)} className={ ctrlClass } />
      </Col>
    </Form.Group>
  );
}

function SubmitButton({ pending }) {
  return (
    <Button type="submit" className="float-end" disabled={ pending }>
      <i className={`fa ${pending ? 'fa-spinner fa-spin' : 'fa-paper-plane'}`}></i>
      { pending ? ' Sending email...' : ' Send' }
    </Button>
  )
}

function ConfirmBox() {
  return (
    <Card border="success" className={ styles.successBox }>
      <Card.Header as="h5">Confirmation</Card.Header>
      <Card.Body>
        <center>
          <div><img src={ imgConfirmation } /></div>
          <div>
            Your email has successfully been sent.<br />
            Sylvia will get back to you as soon as possible.
          </div>
        </center>
      </Card.Body>
    </Card>
  )
}

function AlertBox({ onDismiss, message }) {
  return (
    <Alert variant="danger" onClose={ onDismiss } dismissible>
      <Alert.Heading>Unable to send the email</Alert.Heading>
      <p>{ message }</p>
    </Alert>
  );
}


export default function Contact() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [ state, setState ] = useState({});

  function onEmailSent(resp) {
    setState({ code: 'sent' });
    reset(); // reset the form
  }

  function onSendingError(err) {
    const message = getErrorMessage(err);
    setState({ code: 'error', message });
  }

  function onSubmit(data) {
    if( state.code == 'pending' )
      return;
    onEmailSent({});
    postJson('api/v1/emails', data)
      .then(onEmailSent)
      .catch(onSendingError);
  }

  if( state.code == 'sent' )
    return <ConfirmBox />
  return (
    <ContactForm
      onSubmit={ handleSubmit(onSubmit) }
      onDismissError={ () => setState({}) }
      {...{ state, register, errors }}
    />
  );
};
