import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from 'react-router-dom';

function NavItem({ to, children }) {
  const active = useLocation().pathname.startsWith(to);
  const props = {
    active,
    as: Link,
    children,
    href: to, // required for auto-collapsing on mobile devices. Cf: https://stackoverflow.com/questions/56464444/collapseonselect-in-react-bootstrap-navbars-with-react-router-dom-navlinks
    to,
  }
  return (<Nav.Link { ...props } />);
}

export default function Header() {
  return(
    <Navbar collapseOnSelect expand="md" bg="light">
      <Container>
        <Navbar.Brand className="d-none d-sm-block">Sylvia Prokopowicz Luchez</Navbar.Brand>
        <Navbar.Brand className="d-block d-sm-none">Sylvia Luchez</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav>
            <NavItem to="/bio">Bio</NavItem>
            <NavItem to="/statement">Artist Statement</NavItem>
            <NavItem to="/folders">Projects</NavItem>
            <NavItem to="/contact">Contact</NavItem>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
