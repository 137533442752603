import React from 'react';
import imgWarning from '../../assets/images/warning.png';
import styles from './error_panel.css';

export default function ErrorPanel() {
  return (
    <div className={ styles.errorPanel }>
      <img src={ imgWarning } alt="Error" />
      <p>Something went wrong! Please Try again!</p>
    </div>
  );
}
