import React from 'react';
import { FolderLink } from '../../links';
import { getFullUrl } from '../../../lib/api_helper';
import { projectNameWithPeriod } from '../../../lib/util';
import imgFolder from '../../../../assets/images/folder.png';

export default function FolderIcon(folder) {
  const src = folder.photo ? getFullUrl(folder.photo.thumb.url) : imgFolder;
  return (
    <li>
      <FolderLink folder={ folder }>
        <img src={ src } className="responsive" />
        <span>{ projectNameWithPeriod(folder) }</span>
      </FolderLink>
    </li>
  );
}
