import React from 'react';
import PageWithAsyncText from '../page_with_async_text';
import TextBlock from '../../text_block';

function StatementBody({ text }) {
  return (
    <TextBlock text={ text } />
  );
}

export default function Statement() {
  return <PageWithAsyncText code="statement" Component={ StatementBody } />
};
