import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import FolderIcon from './folder_icon';
import ProjectIcon from './project_icon';
import SlideShow from './slide_show';
// import Breadcrumbs from './breadcrumbs';
import ProjectTitle from './project_title';
import Spinner from '../../spinner';
import ErrorPanel from '../../error_panel';
import { getJson } from '../../../lib/api_helper';
import styles from './folders.css';


function getFolderAndProjectIcons({ projects, sub_folders }) {
  const folderIcons = sub_folders.map( f => <FolderIcon key={`f_${f.folder.id}`} {...f.folder} /> );
  const projectIcons = projects.map( p => <ProjectIcon key={`p_${p.project.id}`} {...p.project} /> );
  return [...folderIcons, ...projectIcons];
}


function ProjectsBox(folder) {
  const [ssOpened, setSsOpened] = useState(false);
  return (
    <div>
      <SlideShow open={ ssOpened } setSsOpened={ setSsOpened } folder={ folder } />
      <ProjectTitle { ...folder } />
      <StartSlideShowButton { ...folder } setState={ setSsOpened } />
      {/*<Breadcrumbs />*/}
      <ul className={ styles.list }>
        { getFolderAndProjectIcons(folder) }
      </ul>
    </div>
  );
}

function StartSlideShowButton({ projects, setState }) {
  if( !projects || !projects.length )
    return null;
  return (
    <div className={ styles.startSlideShowButtonRow }>
      <Button onClick={ () => setState(true) }>
        <i className="fa fa-play"></i>
        Start Slideshow
      </Button>
    </div>
  );
}

export default function Folders() {
  const { id } = useParams();
  const [folder, setProject] = useState(null);
  const [error, setError] = useState(null);
  const url = id ? `api/v1/folders/${id}` : 'api/v1/root_folder';

  useEffect( () => {
    getJson(url)
      .then(resp => setProject(resp.data.folder))
      .catch(err => setError(true));
  }, [id]);

  if( error )
    return (<ErrorPanel />);
  if( folder )
    return (<ProjectsBox { ...folder } />);
  return (<Spinner />);
};
