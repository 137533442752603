import React from 'react';
import Modal from 'react-bootstrap/Modal';
import useCounter from '../../../hooks/use_counter';
import useKeyPress from '../../../hooks/use_key_press';
import PropTypes from 'prop-types';
import { ProjectLink } from '../../links';
import { getFullUrl } from '../../../lib/api_helper';
import { imageStyles } from '../../../lib/util';
import classnames from 'classnames';
import styles from './slide_show.css';


const LEFT_ARROW = {
  className: 'left',
  offset: -1,
  caption: "View Previous Project",
}

const RIGHT_ARROW = {
  className: 'right',
  offset: 1,
  caption: "View Next Project",
}

function SideSection({ data, adjustIndex, disabled }) {
  const { className, offset, caption } = data;
  const css = classnames(styles[className], styles.side, {[styles.disabled]: disabled});

  return (
    <aside className={ css } title={ !disabled && caption || null } onClick={ !disabled && (() => adjustIndex(offset)) || null }>
      <span className={`fa fa-regular fa-chevron-${className}`}></span>
    </aside>
  )
}


function SlideShow({ open,  setSsOpened, folder }) {
  if( !open )
    return null;

  const { index, adjustIndex, atLowBound, atTopBound } = useCounter({ count: folder.projects.length });
  const project = folder.projects[index].project;

  useKeyPress({
    'ArrowLeft': () => adjustIndex(-1),
    'ArrowRight': () => adjustIndex(1),
  })

  return (
      <Modal
        show={ open }
        className={ styles.modal }
        backdrop="static"
        fullscreen={ true }
        onHide={ () => setSsOpened(false) }
        keyboard={ true }
      >
        <Modal.Body>
          <div className={ styles.layout }>
            <div className={ styles.void }></div>
            <SideSection data={ LEFT_ARROW } adjustIndex={ adjustIndex } disabled={ atLowBound } />
            <SideSection data={ RIGHT_ARROW } adjustIndex={ adjustIndex } disabled={ atTopBound } />
            <div className={ styles.exit }>
              <i title="Close slideshow" className="fa fa-regular fa-close" onClick={ () => setSsOpened(false) }></i>
            </div>
            <div className={ styles.title }>
              <h2>{ project.name }</h2>
            </div>
            <div className={ styles.project } style={ imageStyles(project.photo) }>
            </div>
            <div className={ styles.info }>
              <ProjectLink project={ project }>Get More Information</ProjectLink>
            </div>
          </div>
        </Modal.Body>
      </Modal>
  );
}


SlideShow.propTypes = {
  open: PropTypes.bool,
  setSsOpened: PropTypes.func.isRequired,
  folder: PropTypes.object.isRequired,
};

export default SlideShow;
