import { useState } from 'react';


function wrapIndex(value, max) {
  if (value < 0)
    value = 0;
  if (value > max)
    value = max;
  return value;
}


export default function useCounter({ startValue=0, count }) {
  const [index, setIndex] = useState(startValue);
  const adjustIndex = (offset) => { setIndex(currentIndex => wrapIndex(currentIndex + offset, count-1)) };
  const atLowBound = (index === 0);
  const atTopBound = (index === count-1);

  return {
    index,
    adjustIndex,
    atLowBound,
    atTopBound
  };
}
