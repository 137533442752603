import React from 'react';
import { projectNameWithPeriod } from '../../../lib/util';

export default function ProjectTitle(project) {
  if( project.root || !project.name )
    return null;

  return (
    <h2>{ projectNameWithPeriod(project) }</h2>
  );
}
