import React from 'react';
import { getFullUrl } from './api_helper'


export function formatPeriod(from_year, to_year) {
  let dates = [from_year, to_year].filter(d => d);
  if( dates.length === 2 && (dates[0] == dates[1]) )
    dates = [dates[0]];
  return dates.join(' - ');
}

export function projectNameWithPeriod({ name, from_year, to_year }) {
  const period = (from_year || to_year) ? <span>({ formatPeriod(from_year, to_year) })</span> : null;
  return (
    <>
      { name }
      { ' ' }
      { period }
    </>
  );
}


const escapeUrl = (url) => url.replaceAll(/\'/g, "\\'");
// will stack two images as background property:
// - the thumbnail, on the further layer (to start showing the more pixelated version first while the large image loads)
// - the large version, on the closest layer, with more details
export function imageStyles( photo ) {
  return {
    backgroundImage: `url('${getFullUrl(escapeUrl(photo.large.url))}'), url('${getFullUrl(escapeUrl(photo.thumb.url))}')`
  };
}
